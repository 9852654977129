import React, { FC, memo } from 'react'

const Pulse: FC = () => {
  return (
    <div>
      <p>OK</p>
    </div>
  )
}

export default memo(Pulse)
